<template>
  <transition name="fade">
    <div class="welcome-size" v-show="welcomeisVisible">
      <div class="msg-container">
        <div class="pre-line ">
        {{ welcome }}
      </div>
      </div>
    </div>
  </transition>
</template>
    
<script setup>
import "@/assets/fonts/font.css";
import { ref, onMounted, defineEmits, defineExpose } from "vue";
const welcomeisVisible = ref(true);
const emit = defineEmits(["visible"]);
const welcome = ref("THE\nMOST\nBORING\n30SEC\nEVER"); //可变字符串

onMounted(() => {
  afterStart(welcome.value);
});

const afterStart = (value) => {
  welcome.value = value;
  welcomeisVisible.value = true;
  setTimeout(() => {
    welcomeisVisible.value = false;
    visibleEvent();
  }, 3000); // 3秒后隐藏组件
};

/// 传递回调事件到父控件
const visibleEvent = () => {
  emit("visible", "开始倒计时");
};

/// 暴露子组件方法 这里需要暴露出去 不然父类组件调用不到
defineExpose({
  afterStart,
  welcomeisVisible
});
</script>
    
<style scoped>
.welcome-size {
  position: fixed;
  background-color: black;
  overflow: auto;
}

.msg-container {
  display: flex;
  justify-content: center;
  align-items: center; 
  height: 100vh;
}

.pre-line {
  white-space: pre-line; /* 将空格和换行符保留下来 */
  line-height: 1; /* 设置行间距为3倍字体大小 */
  font-family: "MotionControl-Bold", sans-serif; /* 设置字体*/
  font-size: 115px;
  color: white;
  text-align: center;
  min-height: 600px;
}

/* 定义淡出动画 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>