<template>
  <div @click.stop="startAnimation">
    <div class="container" v-show="coinVisible">
      <div
        class="coin"
        :class="{ 'rotate-animation': isAnimating }"
        @animationend="handleAnimationEnd"
      >
        <div class="coin-container">
          <img class="coin-face" src="@/assets/images/tg_coin.png" alt="" />
        </div>

        <!-- 硬币背面 -->
        <div class="coin-container">
          <img
            class="coin-face coin-face-back"
            src="@/assets/images/tg_coin.png"
            alt=""
          />
        </div>
      </div>
      <div class="coin-num" v-show="countdownVisible">{{ count }}</div>
      <div class="reward" v-show="rewardVisible">
        <div class="coin-num">+</div>
        <IOdometer class="coin-num" :value="rewardRecord"></IOdometer>
      </div>
    </div>
    <div
      class="coin-num"
      v-show="claimVisible"
      :class="{ 'zoom-in-out': isScaleAnimating }"
    >
      {{ claimMsg }}
    </div>
  </div>
</template>
   
<script setup>
import { ref, onMounted, onUnmounted, defineEmits, defineExpose } from "vue";
import "@/assets/fonts/font.css";
import IOdometer from "vue3-odometer";
import "odometer/themes/odometer-theme-default.css";
import { Howl } from "howler";

const isAnimating = ref(false);
const isScaleAnimating = ref(false);
const claimMsg = ref("CLAIM\nNOW!");
const claimVisible = ref(true);
const coinVisible = ref(false);
const rewardVisible = ref(false);
const countdownVisible = ref(true);
const count = ref(3);
const rewardRecord = ref(0);
// 是否可以再次点击领币  只能点击一次
const canrotate = ref(false);
/// 是否回到后台
const appbackstageRef = ref(false);
const joinVisible = ref(false);

/// 倒计时
const timerId = ref(null);
/// 是否启动了倒计时
const isCountingDown = ref(false);

const sound = ref(null);
const claimSound = ref(null);
const emit = defineEmits(["restHome", "getCoinAnimation"]);

const handleAnimationEnd = () => {
  console.log("动画已停止");
  isAnimating.value = false;
  afterRest();
};

/// 点击硬币转动
const startAnimation = () => {
  /// 只能点击一次 在倒计时进行重置
  if (!canrotate.value) {
    return;
  }
  canrotate.value = false;
  isAnimating.value = true;
  rewardVisible.value = true;
  countdownVisible.value = false;
  playSound();

  const min = 0.5;
  const max = 1;
  const random = Math.random(); // 生成一个[0, 1)之间的随机数
  const randomproportion = (random * (max - min) + min).toFixed(1); // 转换为[0.3, 1.0]之间的随机数并保留一位小数
  const amount = 30 * randomproportion;
  rewardRecord.value = amount;

  pauseCountdown();
  coinRoteAnimation(rewardRecord.value);
};

/// 领币声音
const playSound = () => {
  sound.value.play();
  // sound.value.currentTime = 0
};

const playClaimSound = () => {
  claimSound.value.play();
  // claimSound.value.currentTime = 0
};

/// 清理声音播放
const clearSound = () => {
  // sound.src = ''
  sound.value.pause();
  // sound.value.currentTime = 0

  // claimSound.src = ''
  claimSound.value.pause();
  // claimSound.value.currentTime = 0
};

const afterStart = (scaleAnmation) => {
  playClaimSound();
  claimMsg.value = "CLAIM\nNOW!";
  coinVisible.value = false;
  claimVisible.value = true;
  isScaleAnimating.value = scaleAnmation;
  setTimeout(() => {
    // 如果邀请页显示 或者返回后台 则暂停claimVisible 隐藏
    if (stopCountEnble()) {
      return;
    }
    coinVisible.value = true;
    claimVisible.value = false;
    isScaleAnimating.value = false;
    count.value = 3;
    startCountdown();
  }, 1000); // 1秒后隐藏组件
};

const startCountdown = () => {
  console.log("开始硬币的倒计时");
  canrotate.value = true;

  if (!isCountingDown.value) {
    isCountingDown.value = true;
    timerId.value = setInterval(() => {
      //TODO
      if (count.value <= 1) {
        countdownEnd();
        pauseCountdown();
        return;
      }
      if (count.value > 0) {
        count.value--;
      } else {
        /// 倒计时结束 暂停倒计时
        pauseCountdown();
        countdownEnd();
      }
    }, 1000);
  }
};

const pauseCountdown = () => {
  isCountingDown.value = false;
  if (timerId.value) {
    clearInterval(timerId.value);
    timerId.value = null;
  }
};

const joinStartCount = () => {
  joinVisible.value = false;
  continueCount();
};

/// 邀请页或返回前台后 继续倒计时
const continueCount = () => {
  if (!canrotate.value) {
    afterRest();
  } else {
    if (claimVisible.value) {
      afterStart(false);
    } else {
      startCountdown();
    }
  }
};

const joinPauseCount = () => {
  joinVisible.value = true;
  pauseCountdown();
};

/// 领完币或Miss
const afterRest = () => {
  /// 倒计时结束 禁止coin旋转
  canrotate.value = false;
  setTimeout(() => {
    /// 如果已弹出邀请页 停止重置首页倒计时逻辑
    if (stopCountEnble()) {
      return;
    }

    restHomeCountDown();
    rewardVisible.value = false;
    countdownVisible.value = true;
    rewardRecord.value = 0;
  }, 2000);
};

/// 传递回调事件到父控件 重置首页倒计时
const restHomeCountDown = () => {
  emit("restHome");
};

/// 开始领币动画 传递回父类 执行数字滚动事件
const coinRoteAnimation = (amount) => {
  emit("getCoinAnimation", amount);
};

/// 倒计时结束
const countdownEnd = () => {
  claimMsg.value = "MISSED";
  coinVisible.value = false;
  claimVisible.value = true;
  afterRest();
};

onMounted(() => {
  // sound.src = require('@/assets/tg_coin_rotate.mp3')
  // claimSound.src = require('@/assets/tg_countdown_out.mp3')
  // document.addEventListener('visibilitychange', handleVisibilityChange);

  sound.value = new Howl({
    src: require("@/assets/tg_coin_rotate.mp3"),
    volume: 0.5,
    onend: function () {},
  });

  claimSound.value = new Howl({
    src: require("@/assets/tg_countdown_out.mp3"),
    volume: 0.5,
    onend: function () {
      console.log("声音播放Finished!");
    },
  });
});

/// 弹出加入页或 欢迎页 回到后台 时停止倒计时
const stopCountEnble = () => {
  if (joinVisible.value || appbackstageRef.value) {
    return true;
  } else {
    return false;
  }
};

/// 用户回到后台
const hideApp = () => {
  console.log("用户回到了后台");
  appbackstageRef.value = true;
  // 执行回到后台的逻辑
  pauseCountdown();
};

/// 用户返回前台 后台返回前台如果不在领币页 则不重置chaim
const visibleApp = () => {
  console.log("用户返回前台");
  appbackstageRef.value = false;
  // 执行返回前台的逻辑
  continueCount();
};

onUnmounted(() => {
  // 组件卸载时清理定时器
  pauseCountdown();
  clearSound();
  // document.removeEventListener('visibilitychange', handleVisibilityChange);
});

/// 暴露子组件方法 这里需要暴露出去 不然父类组件调用不到
defineExpose({
  afterStart,
  afterRest,
  joinStartCount,
  joinPauseCount,
  hideApp,
  visibleApp,
  canrotate,
});
</script>
   
<style scoped>
.container {
  display: fixed;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.coin-container {
  width: 150px; /* 设置容器宽度 */
  height: 150px; /* 设置容器高度 */
  overflow: hidden;
}

.coin {
  display: flex;
  margin: auto;
  position: relative;
  max-width: 150px;
  max-height: 150px;
  transform-style: preserve-3d;
}

/* 定义旋转动画 */
.rotate-animation {
  animation: rotate-and-stop 3s;
}

.reward {
  display: flex;
  justify-content: center;
}

.coin-num {
  margin-top: 15px;
  font-size: 90px;
  white-space: pre-line; /* 将空格和换行符保留下来 */
  line-height: 70px;
  font-family: "MotionControl-Bold", sans-serif; /* 设置字体*/
  color: #f53000;
}

.wallet_num {
  font-family: "JohnHancockCP", sans-serif; /* 设置字体*/
  font-size: 30px;
  color: white;
}

.coin-face {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.coin-face-back {
  left: 0px;
  transform: rotateY(180deg);
}

/* .coin-click {
  display: flex;
  position: absolute;
  width: 70px;
  height: 70px;
  right: 0px;
  bottom: -20px;
} */

@keyframes rotate-and-stop {
  0% {
    transform: rotateY(0deg);
  }
  /* 50% {
    transform: rotateY(3600deg);
    animation-timing-function: ease-in-out;
  } */
  100% {
    transform: rotateY(3600deg);
  }
}

.zoom-in-out {
  animation: zoom-in-out 0.2s;
}

@keyframes zoom-in-out {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
</style>