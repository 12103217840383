// 在 Vue 3 中使用 axios 进行 HTTP 请求的简单封装
import axios from 'axios';
 
// 创建 axios 实例
const service = axios.create({
  //baseURL: process.env.VUE_APP_BASE_API, // api 的 base_url
  // 测试域名 : https://app.test.api.30sec.net
  // 正式域名 : https://app.api.30sec.net
  // baseURL: 'https://app.test.api.30sec.net',
  // baseURL:'/v1',

  timeout: 5000 // 请求超时时间
});
 
// 请求拦截器
service.interceptors.request.use(
  config => {
    // 可以在这里添加请求头等信息
    // 例如：config.headers['Authorization'] = 'Bearer ' + token;
    config.headers['Content-Type'] = 'application/json; charset=utf-8';
    return config;
  },
  error => {
    // 请求错误处理
    console.log(error); // for debug
    Promise.reject(error);
  }
);
 
// 响应拦截器
service.interceptors.response.use(
  response => {
    // 对响应数据做处理，例如只返回 data 中的数据
    const res = response.data;
    // 如果返回的状态码为200，说明成功，可以直接使用数据
    if (res.code == '200') {
      return res.data;
    } else {
      // 其他状态码都当作错误处理
      // 可以在这里对不同的错误码进行不同处理
      return Promise.reject(res || 'error');
    }
  },
  error => {
    // 服务器返回的错误处理
    console.log('err' + error); // for debug
    return Promise.reject(error);
  }
);
 
export default service;