<template>
    <div class="container">
        <div>
    <button @click="playAnimation">播放动画</button>
    <div ref="animationContainer" style="width: 200px; height: 200px;"></div>
  </div>
    </div>
</template>


<script setup>
import { onMounted, ref } from 'vue';
import lottie from 'lottie-web';
// import animationData from './lottieh.json'; // 你的Lottie动画数据
 
const animationContainer = ref(null);
let animation = null;
 
onMounted(() => {
  animation = lottie.loadAnimation({
    container: animationContainer.value,
    renderer: 'svg',
    loop: false,
    autoplay: false,
    animationData: require("@/assets/lottieh.json")
  });

  animation.setSpeed(1)
});
 
const playAnimation = () => {
  if (animation) {
    animation.stop()
    animation.play();
  }
};
</script>

<style >

.container {
  position: fixed;
  display: fixed;
  background-color: orange;
  width: 100vw;
  height: 100wh;
}

</style>