<template>
    <div class="container">
        <div class="top-container ">
            <img class="msg-container" src="@/assets/images/tg_download_msg.png" alt="">
        </div>
        <div class="bottom-container">
            <div class="font-size under-line" @click.stop="clickDownLoad"  >DOWNLOAD 30SEC APP</div>
            <div class="font-size" @click.stop="clickCopyCode" style="margin-top: 30px;" :style="{color:copyColor,textDecoration:clickIng ? 'none' : 'underline'}">{{ copyString }}</div>
        </div>
        
    </div>
</template>

<script setup>
import "@/assets/fonts/font.css";
import { ref } from "vue";

const copyString = ref('COPY CODE')
const copyColor = ref('#FFFFFF')
const clickIng = ref(false)

const appStore = 'https://apps.apple.com/us/app/30sec-land-of-stories/id1544200325'
const googlePlay = 'https://play.google.com/store/apps/details?id=com.left.halfMinDApp'

const clickDownLoad = () => {
    var url = googlePlay
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        url = appStore
      } else if (/(Android)/i.test(navigator.userAgent)) { //判断Android

      }
      console.log(url)
      window.Telegram.WebApp.openLink(url)
}

const clickCopyCode = () => {
    if (clickIng.value) {
        return
    }
    navigator.clipboard.writeText('30SBORED')
    copyString.value = 'Copied'
    copyColor.value = '#888888'
    clickIng.value = true
    setTimeout(() => {
        clickIng.value = false
        copyString.value = 'COPY CODE'
        copyColor.value = '#FFFFFF'
  }, 2000); 

}

</script>

<style scoped>

.container {
    display: flex;
    align-items: center;
    flex-direction: column; 
    background-color: black;
    /* opacity: 0.9; */
    justify-content: space-between;
}

.top-container {
    display: flex;
    width: 100vw;
    height: 80%;
    justify-content: center;
    align-items: center;
}

.bottom-container {
    height: 20%;
    width: 100vw;
}

.msg-container {
    width: 220px;
    object-fit: contain;
}

.font-size {
  font-family: "JohnHancockCP", sans-serif; /* 设置字体*/
  font-size: 20px;
  color: white;
}

.under-line {
  text-decoration: underline;
}

</style>