<template>
   <div>
    <!-- <TGHome></TGHome> -->
    <TGCountdown></TGCountdown>
   </div>
  
</template>

<script>

import TGCountdown from "./components/TGCountdown.vue";
export default {
  name: 'App',
  components: {
    // TGHome, // 注册子组件后方可在template中使用
    TGCountdown
  }
}
</script>

<style>
#app {
  text-align: center;
  color: #2c3e50;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
}


</style>
