import service from '@/utils/http';
 
// 获取广告列表
// export function getVideoList(params) {
//   return service.get('/v1/content/Home', { params });
// }
 
// 获取用户详情 
export function getUserInfo(params) {
  // return service.get(`/v1/tg/TgUser/${tgId}`,{ params });
  return service.post('/v1/tg/TgUser',params);
}
 
// 获得奖励
export function tgRewardRecord(params) {
  return service.post('/v1/tg/RewardRecord',params)
}

/// 获取用户列表
export function tgInviteList(params) {
  return service.post('/v1/tg/TgUser/inviteList',params)
}


// // 更新用户信息
// export function updateUser(id, data) {
//   return service.put(`/user/update/${id}`, data);
// }
 
// // 删除用户
// export function deleteUser(id) {
//   return service.delete(`/user/delete/${id}`);
// }