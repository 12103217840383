import CryptoJS from 'crypto-js'
 
export const encryptedString = (encryptedText) => {
    const key = CryptoJS.enc.Utf8.parse('7a0o*^2&c-q+HbcALXGF59mcbqYxgga5'); // 16 bytes key
    const iv = CryptoJS.enc.Utf8.parse('3XwIK*y&zGwQVAIi'); // 16 bytes IV
    const paddedMessage = CryptoJS.enc.Utf8.parse(encryptedText); 
    // Encrypt
    const encrypted = CryptoJS.AES.encrypt(paddedMessage, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }